import React from 'react';
import { toursModalOpenVar } from '@apolloCli/policies/uiPolicy';
import clsx, { ClassValue } from 'clsx';
import { useUser } from '@hooks/useUser';

type Props = {
  className?: ClassValue;
};

export const ToursButton = ({ className }: Props) => {
  const { isLogin, loading } = useUser();

  const handleClick = () => {
    toursModalOpenVar(true);
  };

  if (loading || !isLogin) return null;

  return (
    <div onClick={handleClick} className={clsx(className)}>
      View Tour
    </div>
  );
};
