import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';
import { useAreaInfo } from '@hooks/useAreaInfo';
import { useParentAreas } from '@hooks/useParentAreas';
import { useListing } from '@hooks/useListing';

export const useSampleArea = () => {
  const router = useRouter();
  const { pid, providerId, aid } = router.query;
  const [hasSample, setHasSample] = useState(false);
  const [hideSampleButton, setHideSampleButton] = useState(false);
  const { listing } = useListing({
    areaId: Number(aid),
    uid: String(pid),
    providerId: String(providerId),
  });
  const { areaId: areaIdInfo } = useAreaInfo();
  const areaId = areaIdInfo || listing?.areaId;
  const {
    parentAreasData,
  } = useParentAreas({ areaId, skip: !areaId });
  const { activeOrdersV2 } = useAreaActiveOrdersV2();

  useEffect(() => {
    if (activeOrdersV2) {
      const sample = activeOrdersV2.areas.find((area) => area.isSampleArea);
      setHideSampleButton(!!activeOrdersV2.areas.find((area) => area.areaId === areaId));
      if (!sample) {
        setHasSample(false);
      } else {
        const sampleParent = parentAreasData?.parentAreas.find((area) => area.areaId === sample?.areaId);
        setHasSample((sample?.areaId === areaId) || (sample?.areaId === listing?.areaId) || !!sampleParent);
      }
    }
  }, [activeOrdersV2, areaId, listing, parentAreasData]);

  return {
    hasSample,
    hideSampleButton
  };
};
