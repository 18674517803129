export enum PropertyTypeEnum {
  ENTIRE_HOME = 'entire_home',
  PRIVATE_ROOM = 'private_room',
  SHARED_ROOM = 'shared_room',
  HOTEL = 'is_hotel',
}

export enum PlanTypeEnum {
  PRO_PLAN = 'PRO',
  BASIC_PLAN = 'BASIC',
  FREE_PLAN = 'FREE',
}

export enum MetricsOptionsExploreEnum {
  currency = 'currency',
  percent = 'percent',
  text = 'text',
}

export enum ExploreTableOptionsValueEnum {
  AREA_TYPE = 'areaType',
  AREA_NAME = 'areaName',
  AVG_AVAILABLE_COUNT = 'avgAvailableCount',
  ADR = 'adr',
  ADJ_OCC_RATE = 'adjOccupancyRate',
  ADJ_REVPAL = 'adjRevpal',
  MEAN_STAY_LENGHT = 'meanStayLength',
}

export enum ExploreTableOptionsTitleEnum {
  AREA_TYPE_TITLE = 'Area Type',
  AREA_NAME_TITLE = 'Area Name /Postal code',
  AVG_AVAILABLE_COUNT_TITLE = 'Available Supply',
  ADR_TITLE = 'Avg. Daily Rate',
  ADJ_OCC_RATE_TITLE = 'Occupancy Rate',
  ADJ_REVPAL_TITLE = 'Revenues',
  MEAN_STAY_LENGHT_TITLE = 'Mean Stay Length',
}
