import gql from 'graphql-tag';
import { ProviderEnum } from '@apolloCli/policies/uiPolicy';

type CountActive = {
  countActive: number;
  providerId: ProviderEnum;
};

export type ParentChildAreas = {
  areaId: number;
  areaName: string;
  areaType: string;
  listingCount: number;
  planNickname: string;
  countActive: CountActive[];
};

export const GET_CHILD_AREAS = gql`
  query getChildAreas($areaId: Int!) {
    childAreas(areaId: $areaId) {
      areaId
      areaName
      areaType
      listingCount
      planNickname
      countActive {
        countActive
        providerId
      }
    }
  }
`;

export const GET_PARENT_AREAS = gql`
  query getParentAreas($areaId: Int!) {
    parentAreas(areaId: $areaId) {
      areaId
      areaName
      areaType
      listingCount
      planNickname
    }
  }
`;
