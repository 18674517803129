import gql from 'graphql-tag';

export type AddSampleArea = {
  addSampleArea: {
    result: boolean;
    sampleAreaId: number;
  }
};

export const ADD_SAMPLE_AREA = gql`
mutation addSampleArea{
  addSampleArea {
    result
    sampleAreaId
  }
}
`;
export const DELETE_SAMPLE_AREA = gql`
mutation removeSampleArea{
  removeSampleArea {
    result
    sampleAreaId
  }
}
`;
