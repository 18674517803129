import { addNotification } from '@hooks/useUiNotifications';
import { SUPPORTED_BROWSER_REGEX } from '@utils/browserChecker';
import { useEffect } from 'react';
import cookie from 'js-cookie';
// Old browsers are not supported in our codebase.
// The browserlist (standar to define a list of supported browsers) is this:
// npx browserslist ">0.3%, not ie 11, not dead, not op_mini all"
// This means: take all browser use stats in the world and pick any browser with at least a 0.3 % of use,
// remove IE11, Opera mini in all versions and any other dead browser.
// We created automatically a regular expression when we run "npm run supportedBrowsers".
// if we dicide to have a change the browser list we can consult the next documentation.
// https://github.com/browserslist/browserslist-useragent-regexp

// https://github.com/browserslist/browserslist#browsers-data-updating
// update the list of browsers with npx browserslist@latest --update-db

export const useBrowserChecker = () => {
  useEffect(() => {
    const ua = navigator.userAgent;
    const isSocialMediaOrIphone =
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Twitter') > -1 ||
      ua.indexOf('Instagram') > -1 ||
      ua.indexOf('iPhone') > -1;
    const showMessage = cookie.get('no_supported_browser') === 'showed';

    if (!SUPPORTED_BROWSER_REGEX.test(ua) && !isSocialMediaOrIphone && !showMessage) {
      addNotification({
        title: 'Your browser is not supported',
        type: 'info',
        description: `Looks like you’re using a browser that is not supported.
        For a better experience try using a latest version of Chrome, Safari or Firefox. :)`,
        expires: 10000,
      });
      cookie.set('no_supported_browser', 'showed', { expires: 1 });
    }
  }, []);
};
