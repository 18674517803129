import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useReactiveVar } from '@apollo/client';
import { snackBarVar } from '@apolloCli/policies/uiPolicy';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';

import styles from './SnackBar.module.scss';

export const SnackBar = () => {
  const { planType } = useAreaActiveOrdersV2();
  const isFree = planType === 'FREE';
  const snackBar = useReactiveVar(snackBarVar);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (snackBar) {
      timer = setTimeout(() => {
        snackBarVar(null);
      }, snackBar.timer);
    }
    return () => clearTimeout(timer);
  }, [snackBar]);

  return snackBar ? (
    <div className={clsx(styles.Container, snackBar && styles.Active, isFree && styles.FreeSnackbar)}>
      <div className={styles.InfoContainer}>
        <div className={clsx(styles.Content)}>{snackBar?.node}</div>
        <div className={styles.CloseIcon} onClick={() => snackBarVar(null)}>
          <img src="/images/icons/icon-close-white.svg" alt="close icon" />
        </div>
      </div>
    </div>
  ) : null;
};
