import React from 'react';
import clsx, { ClassValue } from 'clsx';

import styles from './Badge.module.scss';

type BadgeColor = 'red' | 'green';

type Position = 'top-right' | 'top-left';

type Props = {
  show: boolean;
  content?: string | number;
  color?: BadgeColor;
  className?: ClassValue;
  classNameChildren?: ClassValue;
  position?: Position;
  onClick?: () => void;
  dataGuideTourId?: string;
  children?: React.ReactNode;
};

export const Badge: React.FC<Props> = ({
  show = false,
  content,
  children,
  className,
  classNameChildren,
  color = 'red',
  position,
  onClick,
  dataGuideTourId,
}) => (
  <div className={styles.Container}>
    {show && (
      <div
        className={clsx(
          styles.Badge,
          color === 'red' && styles.ThemeRed,
          color === 'green' && styles.ThemeGreen,
          position === 'top-right' && styles.TopRight,
          position === 'top-left' && styles.TopLeft,
          content && styles.Content,
          !content && styles.NoContent,
          className,
        )}
      >
        {content}
      </div>
    )}
    <div onClick={onClick} className={clsx(styles.Children, classNameChildren)} data-guide-tour-id={dataGuideTourId}>
      {children}
    </div>
  </div>
);
