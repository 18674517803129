/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useReactiveVar } from '@apollo/client';
import { SnackBar } from '@components/feedback/SnackBar/SnackBar';
import { WizardState } from '@components/auth/LoginWizard/LoginWizard.interface';
import { CurrencyPopUp } from '@pageComponents/ShareComponents/Currency/CurrencyPopUp/CurrencyPopUp';
import { isCurrencyModalOpenVar, isLoginModalOpenVar } from '@apolloCli/policies/uiPolicy';
import { useUser } from '@hooks/useUser';
import { useBrowserChecker } from '@hooks/useBrowserChecker';
import { useCurrency } from '@hooks/useCurrency';
import { useLogin } from '@hooks/useLogin';
import { setPreviousURL } from '@store/actions';
import { useIdentify } from '@hooks/useIdentify';
import { ToursModal } from '@pageComponents/ShareComponents/ToursModal/ToursModal';
import { LoginContent } from '@pageComponents/ShareComponents/LoginContent/LoginContent';
import { NotificationCenter } from '@pageComponents/ShareComponents/NotificationCenter/NotificationCenter';
import * as gtag from '@utils/gtag';

import { useAppyToProgram } from './Login';
import { Header } from './Header/Header';
import { MainMenu } from './MainMenu/MainMenu';
import styles from './Dashboard.module.scss';

type Props = {
  hideSnackBar?: boolean;
  children: React.ReactNode;
};

export const Dashboard: React.FC<Props> = ({ children, hideSnackBar }) => {
  const [state, setState] = useState<WizardState>({ step: 'signin' });
  const isProfile = state.step === 'profiles';
  const { user, isLogin, loading: userLoading } = useUser({ fetchPolicy: 'network-only' });
  const { logIn } = useLogin();
  const isLoginOpen = useReactiveVar(isLoginModalOpenVar);
  const { currency } = useCurrency();
  const clearLocalStorageInterval = 8 * 60 * 60 * 1000; // 8 hours
  const { asPath, query, replace, pathname } = useRouter();
  const isHomepage = asPath === '/';
  const { signin, ...restQuery } = query;

  useAppyToProgram();
  useBrowserChecker();
  useIdentify();

  const onClickCurrency = () => {
    isCurrencyModalOpenVar(true);
  };

  // restart the login step on open the signin Modal
  useEffect(() => {
    if (isLoginOpen && !isLogin) {
      setState({ step: 'signin' });
    }
  }, [isLoginOpen, isLogin]);

  // Show profile selector if user is logged but no selected profiles.
  useEffect(() => {
    const userDescriptionType = !!user?.userDescriptionType;
    if (isLogin && !userLoading && !userDescriptionType) {
      setState({ step: 'profiles' });
      isLoginModalOpenVar(true);
    }
  }, [isLogin, userLoading, user]);

  useEffect(() => {
    setPreviousURL(asPath);
    // eslint-disable-next-line camelcase
    const { __clerk_status, __clerk_created_session, ...rest } = query;
    // eslint-disable-next-line camelcase
    if (__clerk_status || __clerk_created_session) {
      replace({
        query: {
          ...rest,
        },
      });
    }
  }, [asPath, replace, query]);

  useEffect(() => {
    const localStorageClearChecker = setInterval(() => {
      for (const key in localStorage) {
        const isCurrencyEntry = key.startsWith('currency-');
        const value = localStorage.getItem(key);

        if (value && isCurrencyEntry) {
          const obj = JSON.parse(value);
          if (obj.expiration) {
            const today = dayjs();
            const expirationDate = dayjs(obj.expiration);
            const deleteEntry = expirationDate.isSame(today) || expirationDate.isBefore(today);

            if (deleteEntry) {
              localStorage.removeItem(key);
            }
          }
        }
      }
    }, clearLocalStorageInterval);

    return () => clearInterval(localStorageClearChecker);
  }, [clearLocalStorageInterval]);

  useEffect(() => {
    if (!isLogin && !isHomepage) {
      logIn();
    }
  }, [isLogin, isHomepage, logIn]);

  useEffect(() => {
    if (query.signin === 'true' && (query.utm_source || query.utm_term || query.utm_content)) {
      gtag.event({ category: 'conversion', sendTo: `${gtag.GA_TRACKING_ID}/16UcCOqy0pUCEIb_sd4C` });

      const cleanPathname = {
        pathname,
        query: restQuery,
      };

      replace(cleanPathname, undefined, { shallow: true });
    }
  }, [asPath, pathname, query, replace, restQuery]);

  return (
    <div className={styles.DashboardMaster}>
      <Header
        currency={{
          ...currency,
          onClick: onClickCurrency,
        }}
      />
      <MainMenu />
      <div className={clsx(styles.ContainerContent)}>{children}</div>
      {((!isLogin && isLoginOpen) || isProfile) && <LoginContent type="analyze" />}
      <CurrencyPopUp />
      {!hideSnackBar && <SnackBar />}
      <ToursModal />
      <NotificationCenter />
    </div>
  );
};
