/* eslint-disable no-param-reassign */
import { UpsertCompetitorGroup } from '@hooks/useCompetitorSet';
import { MarketResponse } from '@hooks/useCustomMarket';

import Store from './store';

export const setLastCustomCompSetInStore = (lastCustomCompSet: UpsertCompetitorGroup) => {
  Store.update((s) => {
    s.lastCustomCompSetInStore = lastCustomCompSet;
  });
};

export const setLastCustomMarketInStore = (lastCustomMarket: MarketResponse) => {
  Store.update((s) => {
    s.lastCustomMarketInStore = lastCustomMarket;
  });
};

export const setPreviousURL = (previousURL: string) => {
  Store.update((s) => {
    s.previousUrls = [...s.previousUrls, previousURL].slice(-2);
  });
};

export const clearLastCustomCompSetInStore = () => {
  Store.update((s) => {
    s.lastCustomCompSetInStore = null;
  });
};

export const clearLastCustomMarketInStore = () => {
  Store.update((s) => {
    s.lastCustomMarketInStore = null;
  });
};

export const clearPreviousURL = () => {
  Store.update((s) => {
    s.previousUrls = [];
  });
};
