import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@components/general/Button/Button';
import { useMutation } from '@apollo/client';
import { ADD_SAMPLE_AREA, AddSampleArea } from '@apolloCli/queries/sampleArea';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';
import { LogoDoors } from '@components/iconography/LogoDoors/LogoDoors';
import { useSampleArea } from '@hooks/useSampleArea';
import { useUser } from '@hooks/useUser';

import styles from './SampleAreaButton.module.scss';

type Props = {
  dataTestId?: string;
};

export const SampleAreaButton: React.FunctionComponent<Props> = () => {
  const router = useRouter();
  const { isLogin } = useUser();
  const { hasSample, hideSampleButton } = useSampleArea();
  const [loadingSample, setLoadingSample] = useState(false);
  const { loading, refetchActiveOrdersV2 } = useAreaActiveOrdersV2();
  const [mutateAddSampleArea] = useMutation<AddSampleArea>(ADD_SAMPLE_AREA);
  const goToSample = async () => {
    try {
      setLoadingSample(true);
      const response = await mutateAddSampleArea();
      await refetchActiveOrdersV2();
      router.push(`/investor/historical/${response.data?.addSampleArea.sampleAreaId}`);
    } catch (e: any) {
      const {
        data: { activeOrdersV2 },
      } = await refetchActiveOrdersV2();
      const sample = activeOrdersV2?.areas.find((area) => area.isSampleArea);
      if (e.message === 'Client is already subscribed to the sample area' && sample) {
        router.push(`/investor/historical/${sample.areaId}`);
      }
    } finally {
      setLoadingSample(false);
    }
  };

  if (!isLogin || loading || hasSample || hideSampleButton) {
    return null;
  }

  return (
    <Button theme="lightBlue" onClick={goToSample} dataTestId="sample-area" className={styles.SampleButton}>
      {loadingSample ? <LogoDoors size="s" loop /> : 'View Unlocked Market'}
    </Button>
  );
};
