import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Brand } from '@components/iconography/Brand/Brand';
import { Chevron } from '@components/iconography/Chevron/Chevron';
import { useReactiveVar } from '@apollo/client';
import { isSideMenuOpenVar, isSideFiltersOpenVar, menuAlertBadgeVar } from '@apolloCli/policies/uiPolicy';
import { useDefaultArea } from '@hooks/useDefaultArea';
import { useOutsideAlerter } from '@hooks/useOutsideAlerter';
import { useLastVisitedArea } from '@hooks/useLastVisitedArea';

import { headerParameters, TabURLSEnum } from '../Header/Header.logic';

import { MainMenuTab } from './MainMenuTab/MainMenuTab';
import styles from './MainMenu.module.scss';

export const MainMenu: React.FC = () => {
  const lastVisitedArea = useLastVisitedArea();
  const { DEFAULT_AREA_ID } = useDefaultArea({ skip: !!lastVisitedArea });
  const ref = useRef<HTMLDivElement>(null);
  const isSideMenu = useReactiveVar(isSideMenuOpenVar);
  const menuAlertBadge = useReactiveVar(menuAlertBadgeVar);
  const { isInvestor, isHost, investorOptions, hostOptions } = headerParameters();
  const [openInvestor, setOpenInvestor] = useState(isInvestor);
  const [openHost, setOpenHost] = useState(isHost);
  const router = useRouter();
  const { pathname } = router;

  const menuToggle = () => {
    const isSmallScreen = Number(window?.innerWidth) < 1280;
    if (isSideMenu) {
      isSideMenuOpenVar(false);
    } else if (!isSideMenu && !isSmallScreen) {
      isSideMenuOpenVar(true);
      isSideFiltersOpenVar(true);
    } else {
      isSideMenuOpenVar(true);
      isSideFiltersOpenVar(false);
    }
  };

  const openInvestorHandle = () => {
    setOpenInvestor(!openInvestor);
    setOpenHost(false);
  };

  const openHostHandle = () => {
    setOpenInvestor(false);
    setOpenHost(!openHost);
  };

  const openEnterprise = () => {
    router.push(TabURLSEnum.ENTERPRISE_URL);
    isSideMenuOpenVar(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (menuAlertBadge) {
      timer = setTimeout(() => {
        menuAlertBadgeVar(false);
      }, 20000);
    }
    return () => clearTimeout(timer);
  }, [menuAlertBadge]);

  useOutsideAlerter(
    ref,
    () => {
      isSideMenuOpenVar(false);
    },
    {
      disableTouchStart: true,
    },
  );

  return (
    <div>
      {isSideMenu && <div className={styles.GrayBlur} />}
      <div
        data-sidemenu
        className={clsx(styles.SideMenu, isSideMenu && styles.active, isSideMenu && styles.MobileActive)}
        ref={ref}
      >
        <div className={clsx(styles.LogoContainer, isSideMenu && styles.IsMenuOpen)}>
          <Brand href={TabURLSEnum.HOME_URL} hideName={!isSideMenu} />
        </div>
        <div>
          <div className={styles.MenuContainer}>
            <div className={clsx(styles.Item, openInvestor && styles.Active)} onClick={openInvestorHandle}>
              Investor <Chevron active={openInvestor} />
            </div>
            {openInvestor && (
              <>
                {investorOptions.map((option) => (
                  <MainMenuTab
                    key={option.label}
                    option={option}
                    defaultArea={DEFAULT_AREA_ID}
                    menuToggle={menuToggle}
                    isActive={pathname.includes(option.path)}
                  />
                ))}
              </>
            )}
            <div className={clsx(styles.Item, openHost && styles.Active)} onClick={openHostHandle}>
              Host <Chevron active={openHost} />
            </div>
            {openHost && (
              <>
                {hostOptions.map((option) => (
                  <MainMenuTab
                    option={option}
                    defaultArea={DEFAULT_AREA_ID}
                    menuToggle={menuToggle}
                    isActive={pathname.includes(option.path)}
                  />
                ))}
              </>
            )}
            <div className={styles.Item} onClick={openEnterprise}>
              Enterprise
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
