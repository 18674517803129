import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@components/general/Button/Button';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';
import { useChildAreas } from '@hooks/useChildAreas';
import { PlanTypeEnum } from '@apolloCli/queries/activeOrders';

import { usePurchaseArea, PropsPurchaseArea } from './PurchaseArea.utils';
import styles from './PurchaseAreaButton.module.scss';

export const PurchaseAreaButton: React.FC<PropsPurchaseArea> = ({
  areaId,
  label,
  disabled,
  className,
  componentName,
  text,
}) => {
  const { hideComponent, onClick } = usePurchaseArea(areaId, componentName, label);
  const { planType, activeOrdersV2 } = useAreaActiveOrdersV2();
  const { childAreasData } = useChildAreas({ areaId });
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);

  useEffect(() => {
    setShowUpgrade(
      planType !== PlanTypeEnum.FREE ||
        childAreasData?.childAreas.some((childArea) =>
          activeOrdersV2?.areas.some((activeOrder) => activeOrder.areaId === childArea.areaId),
        ) ||
        false,
    );
  }, [childAreasData, activeOrdersV2, planType]);

  return hideComponent ? null : (
    <Button
      type="button"
      theme="yellowGradient"
      onClick={onClick}
      className={clsx(styles.Button, className, showUpgrade && styles.ButtonBasic, 'purchase-button')}
      disabled={disabled}
    >
      <span className={styles.label}>{text || (showUpgrade ? 'Upgrade your plan' : 'Unlock This Area')}</span>
    </Button>
  );
};
