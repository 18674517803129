import React from 'react';
import { useQuery } from '@apollo/client';
import { Modal } from '@components/communication/Modal/Modal';
import { useCurrency, CurrencyCodes } from '@hooks/useCurrency';
import { isCurrencyModalOpenVar } from '@apolloCli/policies/uiPolicy';
import { GET_CURRENCY_MODAL } from '@apolloCli/queries/ui';
import { CurrencyModal } from '@interfaces/UI';

import { CurrencyList } from '../CurrencyList';

import styles from './CurrencyPopUp.module.scss';

export const CurrencyPopUp: React.FC = () => {
  const { rates, setCurrency, currency } = useCurrency();
  const { data: dataCurrencyModal } = useQuery<CurrencyModal>(GET_CURRENCY_MODAL);
  const isCurrencyModal = dataCurrencyModal?.isCurrencyModal;

  const onCloseModal = () => {
    isCurrencyModalOpenVar(false);
  };

  const handleCurrency = (code: CurrencyCodes) => {
    setCurrency(code);
    setTimeout(() => {
      isCurrencyModalOpenVar(false);
    }, 400);
  };

  return (
    <div>
      <Modal
        isOpen={isCurrencyModal}
        onClose={onCloseModal}
        className={styles.Box}
        closeButtonClass={styles.CloseButton}
      >
        <CurrencyList rates={rates} setCurrency={handleCurrency} currentCurrency={currency.code} />
      </Modal>
    </div>
  );
};
