/* eslint-disable max-len */
export const SUPPORTED_BROWSER_REGEX =
  /((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(12[_.]2|12[_.]([3-9]|\d{2,})|12[_.]4|12[_.]([5-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})[_.]\d+|13[_.]7|13[_.]([8-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})[_.]\d+|14[_.]0|14[_.]([1-9]|\d{2,})|14[_.]4|14[_.]([5-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+)(?:[_.]\d+)?)|(SamsungBrowser\/(13\.0|13\.([1-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+))|(Edge\/(88(?:\.0)?|88(?:\.([1-9]|\d{2,}))?|(89|9\d|\d{3,})(?:\.\d+)?))|((Chromium|Chrome)\/(86\.0|86\.([1-9]|\d{2,})|(8[7-9]|9\d|\d{3,})\.\d+)(?:\.\d+)?)|(Version\/(13\.1|13\.([2-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+|14\.0|14\.([1-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})\.\d+)(?:\.\d+)? Safari\/)|(Firefox\/(85\.0|85\.([1-9]|\d{2,})|(8[6-9]|9\d|\d{3,})\.\d+)\.\d+)|(Firefox\/(85\.0|85\.([1-9]|\d{2,})|(8[6-9]|9\d|\d{3,})\.\d+)(pre|[ab]\d+[a-z]*)?)/;
// Old browsers are not supported in our codebase.
// The browserlist (standar to define a list of supported browsers) is this:
// npx browserslist ">0.3%, not ie 11, not dead, not op_mini all"
// This means: take all browser use stats in the world and pick any browser with at least a 0.3 % of use,
// remove IE11, Opera mini in all versions and any other dead browser.
// We created automatically a regular expression when we run "npm run supportedBrowsers".
// if we dicide to have a change the browser list we can consult the next documentation.
// https://github.com/browserslist/browserslist-useragent-regexp

// https://github.com/browserslist/browserslist#browsers-data-updating
// update the list of browsers with npx browserslist@latest --update-db
