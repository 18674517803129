import React, { useState, useEffect, useRef } from 'react';
import clsx, { ClassValue } from 'clsx';
import { CloseButton } from '@components/general/CloseButton/CloseButton';
import { Box } from '@components/layout/Box/Box';
import { Portal } from '@components/utils/Portal/Portal';

import styles from './Modal.module.scss';

export type Props = {
  isOpen?: boolean;
  className?: ClassValue;
  closeButtonClass?: ClassValue;
  onClose?: (label?: string | undefined) => void;
  afterOpen?: () => void;
  afterClose?: () => void;
  hideCloseButton?: boolean;
  footer?: React.ReactNode;
  modalClassName?: ClassValue;
  backgroundClassName?: ClassValue;
  dataTestId?: string;
  children: React.ReactNode;
};

const addBodyClass = (className: string) => document.body.classList.add(className);
const removeBodyClass = (className: string) => document.body.classList.remove(className);

export const Modal: React.FC<Props> = ({
  isOpen,
  className,
  hideCloseButton,
  onClose,
  afterOpen,
  afterClose,
  children,
  closeButtonClass,
  footer,
  modalClassName,
  backgroundClassName,
  dataTestId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isPortal, setPortal] = useState(isOpen);
  const [isActive, setActive] = useState(isOpen);
  const [isCompleted, setIsCompleted] = useState(false);
  const buttonClosed = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (onClose) {
      onClose('close_btn');
    }
  };
  const outerClose = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (onClose) {
      onClose('outer_modal_ix');
    }
  };

  // basics
  // onOpen: show portal and disable scroll
  // onClose: trigger off the animation
  useEffect(() => {
    if (isOpen) {
      addBodyClass('modal-active');
      setPortal(true);
    } else {
      setActive(false);
    }
    return () => removeBodyClass('modal-active');
  }, [isOpen]);

  // second
  // onPortalOpen: add listener and activate animation
  // onPortalClose:
  useEffect(() => {
    const onComplete = () => {
      setIsCompleted(true);
    };
    const box = ref.current;
    if (isPortal && box) {
      box.addEventListener('transitionend', onComplete);
      setTimeout(() => setActive(true), 1);
    }
    if (!isPortal && box) box.removeEventListener('transitionend', onComplete);
    return () => {
      if (box) box.removeEventListener('transitionend', onComplete);
    };
  }, [ref, isPortal]);

  // third
  // onActive && onFinishAnimation: call afterOpen()
  // onInactive && onFinishAnimation: enable scroll and turn off portal
  useEffect(() => {
    if (isCompleted) {
      if (!isActive) {
        removeBodyClass('modal-active');
        setPortal(false);
      }
      if (isActive && afterOpen) afterOpen();
      if (!isActive && afterClose) afterClose();
      setIsCompleted(false);
    }
  }, [isActive, isCompleted, afterOpen, afterClose]);

  return isPortal ? (
    <Portal>
      <div key="modal" className={clsx(styles.ModalContainer, modalClassName)} data-test-id={dataTestId}>
        <div className={clsx(styles.ModalBackground, backgroundClassName)} onClick={outerClose} />
        <Box ref={ref} className={clsx(styles.Box, isActive && styles.Active, styles.FadeInAnimation, className)}>
          {!hideCloseButton && (
            <div className={styles.Header}>
              <CloseButton className={clsx(styles.Close, closeButtonClass)} onClick={buttonClosed} />
            </div>
          )}
          <div className={clsx(styles.Content)}>{children}</div>
          {footer && <div className={styles.Footer}>{footer}</div>}
        </Box>
      </div>
    </Portal>
  ) : null;
};
