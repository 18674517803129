import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Badge } from '@components/dataDisplay/Badge/Badge';
import { PopOver } from '@components/communication/PopOver/PopOver';

import { headerParameters, TabOptions } from '../Header.logic';

import styles from './SubHeaderTab.module.scss';

type Props = {
  option: TabOptions;
  defaultArea?: number;
  includeBadge?: boolean;
  showBadge?: boolean;
  tooltipDesc?: ReactNode;
};

export const SubHeaderTab: FC<Props> = ({
  option,
  includeBadge = false,
  showBadge = false,
  defaultArea,
  tooltipDesc,
}) => {
  const { generateHeaderRouter } = headerParameters();

  const onClickTabs = () => {
    const isHostTopProperties = option.path === '/host/top-properties';
    generateHeaderRouter({ label: isHostTopProperties ? `${option.label}-HOST` : option.label, defaultArea });
  };

  return (
    <PopOver position="bottom" disable={!tooltipDesc} description={tooltipDesc}>
      {includeBadge ? (
        <Badge
          classNameChildren={clsx(styles.Item, option.isActive && styles.Active)}
          show={showBadge}
          onClick={onClickTabs}
          position="top-right"
          dataGuideTourId={option.dataGuideTourId}
        >
          {option.label}
        </Badge>
      ) : (
        <div
          className={clsx(styles.Item, option.isActive && styles.Active)}
          onClick={onClickTabs}
          data-guide-tour-id={option.dataGuideTourId}
        >
          {option.label}
        </div>
      )}
    </PopOver>
  );
};
