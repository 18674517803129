import React, { FC } from 'react';
import clsx from 'clsx';
import { headerParameters, TabOptions, TabURLSEnum } from '@layout/Dashboard/Header/Header.logic';

import styles from './MainMenuTab.module.scss';

type Props = {
  option: TabOptions;
  defaultArea: number;
  menuToggle: () => void;
  isActive: boolean;
};

export const MainMenuTab: FC<Props> = ({ option, defaultArea, menuToggle, isActive }) => {
  const { generateHeaderRouter } = headerParameters();

  const onClickTabs = () => {
    const isHostTopProperties = option.path === TabURLSEnum.HOST_TOP_PROPERTIES_URL;
    generateHeaderRouter({ label: isHostTopProperties ? `${option.label}-HOST` : option.label, defaultArea });
    menuToggle();
  };
  return (
    <div className={clsx(styles.Item, styles.Sub, isActive && styles.Active)} onClick={onClickTabs}>
      {option.label}
    </div>
  );
};
