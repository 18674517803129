import { ClassValue } from 'clsx';
import { useRouter } from 'next/router';
import { useUser } from '@hooks/useUser';
import { useLogin } from '@hooks/useLogin';
import { appEventWrap } from '@utils/analytics';
import { isSubscribeModalOpenVar } from '@apolloCli/policies/uiPolicy';
import { EventOptions } from '@interfaces/Analitycs';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';

export type PropsPurchaseArea = {
  areaId: number;
  label?: string;
  disabled?: boolean;
  className?: ClassValue;
  componentName?: EventOptions['componentName'];
  text?: string;
};

export const usePurchaseArea = (areaId: number, componentName?: string, label?: string) => {
  const { isLogin } = useUser();
  const { isGodmodeOrHasGlobalPlan } = useAreaActiveOrdersV2();
  const { logIn } = useLogin();
  const router = useRouter();

  const purchaseArea = () => {
    if (!isLogin) {
      logIn();
    } else if (!isSubscribeModalOpenVar()) {
      isSubscribeModalOpenVar(true);
    }
  };

  const eventObjt = {
    areaId,
    onPage: router.pathname,
    componentName: `purchase button ${componentName}`,
  };

  const buttonLabel = label || 'Unlock This Area';

  const onClick = appEventWrap(purchaseArea, 'Conversion Step 1 - Open Purchase Modal', eventObjt);

  const hideComponent = isGodmodeOrHasGlobalPlan && isLogin;

  return {
    buttonLabel,
    hideComponent,
    onClick,
  };
};
