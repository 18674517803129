import React from 'react';
import dynamic from 'next/dynamic';
import cookie from 'js-cookie';
import { useReactiveVar } from '@apollo/client';
import { toursModalOpenVar } from '@apolloCli/policies/uiPolicy';
import { TourDescSelector } from '@components/auth/LoginWizard/TourDescSelector/TourDescSelector';
import { useUser } from '@hooks/useUser';

import { Props as ModalProps } from '../../../components/communication/Modal/Modal';

import styles from './ToursModal.module.scss';

const Modal = dynamic<ModalProps>(
  () => import('../../../components/communication/Modal/Modal').then((module) => module.Modal),
  {
    loading: () => null,
    ssr: false,
  },
);

export const ToursModal = () => {
  const { updateUser } = useUser();
  const isOpen = useReactiveVar(toursModalOpenVar);
  const currency = cookie.get('currency') || '';

  const handleOnClose = () => {
    toursModalOpenVar(false);
  };

  const onClickDescriptionSave = (userDescriptionType: string) => {
    if (userDescriptionType !== '') {
      updateUser({ variables: { input: { userDescriptionType, currency } } });
    } else {
      updateUser({ variables: { input: { currency } } });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} className={styles.Modal}>
      <TourDescSelector next={() => {}} onClickDescriptionSave={onClickDescriptionSave} skip={handleOnClose} />
    </Modal>
  );
};
