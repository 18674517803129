import React from 'react';
import { CurrencyCodes, Rates } from '@hooks/useCurrency';
import clsx from 'clsx';

import styles from './CurrencyList.module.scss';

type CurrencyProps = {
  rates: Rates;
  currentCurrency: string;
  setCurrency: (value: CurrencyCodes) => void;
};

export const CurrencyList: React.FC<CurrencyProps> = ({ rates, setCurrency, currentCurrency }) => (
  <div className={styles.Name}>
    <div className={styles.Title}>
      <h3>Select Currency</h3>
    </div>
    <ul className={styles.Container}>
      {Object.values(rates).map(
        (currency) =>
          currency && (
            <li key={currency.code} className={clsx(styles.Row, currentCurrency === currency.code && styles.Highlight)}>
              <div onClick={() => setCurrency(currency.code)}>
                <span data-test-id={currency.symbol} className={clsx(styles.CurrencySymbol)}>
                  {currency.symbol}
                </span>
                <span>{currency.name}</span>
              </div>
            </li>
          ),
      )}
    </ul>
  </div>
);
