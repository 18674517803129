import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { isSideMenuOpenVar, isNewFollowAreaVar, isPropertyTrackedVar } from '@apolloCli/policies/uiPolicy';
import { useReactiveVar } from '@apollo/client';
import { PurchaseAreaButton } from '@components/general/PurchaseAreaButton/PurchaseAreaButton';
import { SampleAreaButton } from '@components/general/SampleAreaButton/SampleAreaButton';
import { Brand } from '@components/iconography/Brand/Brand';
import { MenuButton } from '@components/navigation/HeaderMobile/HeaderMobile';
import { UserMenu } from '@components/navigation/UserMenu/UserMenu';
import { HelpMenu } from '@components/custom/HelpMenu/HelpMenu';
import { MagnifyingGlassIcon } from '@components/iconography/MagnifyingGlass/MagnifyingGlassIcon';
import { Rate } from '@hooks/useCurrency';
import { useAreaActiveOrdersV2 } from '@hooks/useAreaActiveOrdersV2';
import { useListing } from '@hooks/useListing';
import { useAreaInfo } from '@hooks/useAreaInfo';
import { useDefaultArea } from '@hooks/useDefaultArea';
import { ToursButton } from '@components/general/ToursButton/ToursButton';
import { useLastVisitedArea } from '@hooks/useLastVisitedArea';

import { SubHeaderTab } from './SubHeaderTabs/SubHeaderTab';
import { HeaderTab } from './HeaderTab/HeaderTab';
import { headerParameters } from './Header.logic';
import styles from './Header.module.scss';

type Props = {
  currency?: Rate & {
    onClick?: () => void;
  };
};

export const Header: React.FC<Props> = ({ currency }) => {
  const { areaId } = useAreaInfo();
  const { planType } = useAreaActiveOrdersV2();
  const lastVisitedArea = useLastVisitedArea();
  const { DEFAULT_AREA_ID } = useDefaultArea({ skip: !!lastVisitedArea });
  const router = useRouter();
  const { pid, providerId, aid } = router.query;
  const { listing } = useListing({
    areaId: Number(aid),
    uid: String(pid),
    providerId: String(providerId),
  });
  const newFollowArea = useReactiveVar(isNewFollowAreaVar);
  const newPropertyTracked = useReactiveVar(isPropertyTrackedVar);
  const {
    investorOptions,
    mainHeaderOptions,
    isPropertyDetailsPage,
    isHistoricalPage,
    isHome,
    isEnterprise,
    isAccountPage,
    accountOptions,
    isInvestor,
    isHost,
    hostOptions,
  } = headerParameters();
  const isFree = planType === 'FREE';

  const burgerHandler = () => {
    isSideMenuOpenVar(true);
  };

  const scrollTop = () => {
    window.scrollTo(0, 50);
  };

  return (
    <div className={styles.HeaderContainer}>
      <div className={clsx(styles.HeaderDesktop)}>
        <div className={clsx(styles.Left)}>
          <div>
            <Brand href="/" />
          </div>
          <div className={clsx(styles.TypeMenu)}>
            {mainHeaderOptions.map((option) => (
              <HeaderTab key={option.label} option={option} defaultArea={DEFAULT_AREA_ID} />
            ))}
          </div>
        </div>
        <div className={clsx(styles.Right)}>
          {areaId && <PurchaseAreaButton areaId={areaId} />}
          {isPropertyDetailsPage && listing && (
            <PurchaseAreaButton
              className={styles.PurchaseButton}
              areaId={listing?.areaId}
              text={isFree ? 'Unlock This Area' : 'Upgrade your plan'}
            />
          )}
          <SampleAreaButton />
          <ToursButton className={clsx(styles.Item)} />

          <HelpMenu />
          <UserMenu dataTestId="sign-in-button" currency={currency} />
        </div>
      </div>

      <div className={clsx(styles.HeaderMobile)}>
        <div className={clsx(styles.Left)}>
          <MenuButton onClick={burgerHandler} />
          <Brand href="/" />
        </div>
        <div className={clsx(styles.Right)}>
          {isHistoricalPage && (
            <div className={styles.Icon} onClick={scrollTop}>
              <MagnifyingGlassIcon className={styles.SearchIcon} color="#1D2129" />
            </div>
          )}
          <UserMenu dataTestId="sign-in-button-mobile" currency={currency} />
        </div>
      </div>

      {!isHome && !isEnterprise && (
        <div className={clsx(styles.TypeSubMenu, isAccountPage && styles.TypeSubMenuAccount)}>
          {isAccountPage &&
            accountOptions.map((option) => (
              <SubHeaderTab key={option.label} option={option} defaultArea={DEFAULT_AREA_ID} />
            ))}

          {isInvestor &&
            investorOptions.map((option) => (
              <SubHeaderTab
                key={option.label}
                option={option}
                includeBadge={option.label === 'For You' || option.label === 'Competitor Tracker'}
                showBadge={option.label === 'For You' ? newFollowArea : newPropertyTracked}
                defaultArea={DEFAULT_AREA_ID}
                tooltipDesc={option.desc}
              />
            ))}

          {isHost &&
            hostOptions.map((option) => (
              <SubHeaderTab key={option.label} option={option} defaultArea={DEFAULT_AREA_ID} />
            ))}
        </div>
      )}
    </div>
  );
};
