import { useEffect } from 'react';
import { identify, UserAreaTrait, UserTraits } from '@utils/analytics';
import { PlanTypeEnum } from '@utils/enums';
import { isLogRocket } from '@utils/logrocket';
import LogRocket from 'logrocket';

import { useUser } from './useUser';
import { useAreaActiveOrdersV2 } from './useAreaActiveOrdersV2';
import { useFollowAreas } from './useFollowAreas';

const DEFAULT_NAME = 'Unknown';

export const useIdentify = () => {
  const { user, loading: loadingUser, isLogin } = useUser();
  const { followAreas, followAreasLoading } = useFollowAreas();
  const { activeOrdersV2, loading: loadingV2, isGodmodeOrHasGlobalPlan } = useAreaActiveOrdersV2();

  useEffect(() => {
    if (user && !loadingV2 && !followAreasLoading && !loadingUser && Number.isInteger(activeOrdersV2?.areas.length)) {
      const onlySantaMonica = activeOrdersV2?.areas.length === 1 && activeOrdersV2?.areas[0].isSampleArea;
      const isActiveOrders = activeOrdersV2?.areas && activeOrdersV2?.areas.length > 0 && !onlySantaMonica;
      const followedAreasTraits: UserAreaTrait[] = followAreas.map((followArea) => ({
        areaId: followArea.area.id,
        planType: PlanTypeEnum.FREE_PLAN,
        period: null,
      }));

      const premiumAreasTraits: UserAreaTrait[] =
        activeOrdersV2?.areas.map((area) => ({
          areaId: area.areaId,
          planType: area.planType as PlanTypeEnum,
          period: area.planInterval || null,
        })) || [];

      const areasTraits = [...followedAreasTraits, ...premiumAreasTraits];

      const traitsLogRocket = {
        name: user.name || DEFAULT_NAME,
        email: user.email,
        paying: !!isActiveOrders,
        experiment: 'opens on select area',
      };

      const traistAmplitude: UserTraits = {
        name: user.name || DEFAULT_NAME,
        email: user.email,
        createdAt: user.createdAt,
        userProfile: user.userDescriptionType,
        userType: !!followedAreasTraits.length || !!premiumAreasTraits.length ? 'active' : 'inactive',
        userAccess: isActiveOrders || isGodmodeOrHasGlobalPlan ? 'premium' : 'free',
        userPlans: areasTraits,
      };

      const isLogRocketActive = isLogRocket(isLogin);
      if (isLogRocketActive) {
        LogRocket.identify(user.uuid, traitsLogRocket);
      }

      identify(user.uuid, traistAmplitude);
    }

    // Execute on:
    // 1. Every page load
    // 2. Every time a user updates their profile
    // 3. Every time a user purchases an area
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingV2, user, followAreasLoading, loadingUser, activeOrdersV2?.areas.length]);
};
