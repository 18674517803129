import { useQuery } from '@apollo/client';
import { GET_PARENT_AREAS, ParentChildAreas } from '@apolloCli/queries/accountFlow';

import { useUser } from './useUser';

export const useParentAreas = ({ areaId, skip = false }: { areaId: number | undefined; skip?: boolean }) => {
  const { isLogin } = useUser();
  const {
    data: parentAreasData,
    loading: parentAreasLoading,
    refetch,
  } = useQuery<{ parentAreas: ParentChildAreas[] }>(GET_PARENT_AREAS, {
    variables: { areaId },
    skip: skip || !areaId || !isLogin,
  });

  return {
    parentAreasData,
    parentAreasLoading,
    refetch,
  };
};
