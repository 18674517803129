import { useQuery } from '@apollo/client';
import { GET_CHILD_AREAS, ParentChildAreas } from '@apolloCli/queries/accountFlow';

type ChildAreasOptions = {
  areaId?: number;
  skip?: boolean;
};

export const useChildAreas = (options: ChildAreasOptions) => {
  const { areaId, skip } = options;
  const { data: childAreasData, loading: childAreasLoading } = useQuery<{ childAreas: ParentChildAreas[] }>(
    GET_CHILD_AREAS,
    {
      variables: { areaId },
      skip: !areaId || skip,
    },
  );

  return {
    childAreasData,
    childAreasLoading,
  };
};
