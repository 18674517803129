import React from 'react';
import { ClassValue } from 'clsx';
import { Rate } from '@hooks/useCurrency';

import styles from './HeaderMobile.module.scss';

type Props = {
  className?: ClassValue;
  onClickMenu?: () => void;
  currency?: Rate & {
    onClick?: () => void;
  };
};

export const MenuButton: React.FC<{ onClick: Props['onClickMenu']; dataTestId?: string }> = ({
  onClick,
  dataTestId,
}) => (
  <div data-sidemenu onClick={onClick} data-test-id={dataTestId} className={styles.Flex}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="#1d2129" strokeWidth="1.3">
      <path d="M0 1.3h20 M0 10h20 M0 19.3h20" />
    </svg>
  </div>
);